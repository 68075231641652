/* eslint-disable import/no-anonymous-default-export */
import SignIn from "./pages/SignIn";
import Invoice from "./pages/Invoice";
import InvoiceDetailPage from "./pages/InvoiceDetail";
import PaymentProcessorsDetailPage from "./pages/settings/PaymentProcessorDetailPage";
import PaymentProcessorsPage from "./pages/settings/PaymentProcessorsPage";
import MerchantPage from "./pages/settings/MerchantPage";
import APIListPage from "./pages/settings/APIListPage";
import UsersPage from "./pages/users";
import BankTransactionsPage from "./pages/BankTransactions";
import UserDetailPage from "./pages/users/detail";
import Summary from './pages/Summary';
import Transaction from "./pages/TransactionSummary";
import TransactionRange from "./pages/TransactionSummaryRange";

export default [
    {
        path: "/",
        component: Invoice,
        exact: true,
    },
    {
        path: "/signin",
        component: SignIn,
        exact: true,
        public: true,
    },
    {
        path: "/invoices",
        component: Invoice,
        exact: true,
    },
    {
        path: `/invoices/:invoiceId`,
        component: InvoiceDetailPage,
        exact: true,
    },
    {
        path: "/users",
        component: UsersPage,
        exact: true,
    },
    {
        path: `/users/:userId`,
        component: UserDetailPage,
        exact: true,
    },
    {
        path: "/bank-transactions",
        component: BankTransactionsPage,
        exact: true,
    },

    {
        path: `/settings/merchant`,
        component: MerchantPage,
        exact: true,
    },
    {
        path: "/settings/api",
        component: APIListPage,
        exact: true,
    },
    {
        path: `/settings/payment-processors`,
        component: PaymentProcessorsPage,
        exact: true,
    },
    {
        path: `/settings/payment-processors/:slug`,
        component: PaymentProcessorsDetailPage,
        exact: true,
    },
    {
        path: '/summary',
        component: Summary,
        exact: true
    },
    {
        path: '/transaction',
        component: Transaction,
        exact: true
    },
    {
        path: '/transactionRange',
        component: TransactionRange,
        exact: true
    }
];
