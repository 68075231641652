import {
    PieChartOutlined,
    SettingOutlined,
    BankOutlined,
    HomeOutlined,
    ApiOutlined,
    UserOutlined,
    TransactionOutlined,
    BarChartOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const items = [
    {
        label: (
            <Link to="/invoices">
                <PieChartOutlined /> Нэхэмжлэл
            </Link>
        ),
        key: "invoices",
    },
    {
        label: (
            <Link to="/users">
                <UserOutlined /> Хэрэглэгч
            </Link>
        ),
        key: "users",
    },
    {
        label: (
            <Link to="/bank-transactions">
                <TransactionOutlined /> Банкны хуулга
            </Link>
        ),
        key: "bank-transactions",
    },
    {
        label: (
            <Link to="/summary">
                <BarChartOutlined /> Тайлан
            </Link>
        ),
        key: "summary",
    },
    {
        label: (
            <Link to="/transaction">
                <TransactionOutlined /> Гүйлгээний тайлан
            </Link>
        ),
        key: "transactionSummary",
    },
    {
        label:(
            <Link to="/transactionRange">
                <TransactionOutlined /> Гүйлгээний тайлан өдрөөр
            </Link>
        ),
        key: "transactionSummaryRange"
    },
    {
        label: (
            <span>
                <SettingOutlined /> Тохиргоо
            </span>
        ),
        key: "settings",
        children: [
            {
                label: (
                    <Link to="/settings/merchant">
                        <HomeOutlined /> Байгууллага
                    </Link>
                ),
                key: "settings-merchant",
            },
            {
                label: (
                    <Link to="/settings/api">
                        <ApiOutlined /> API
                    </Link>
                ),
                key: "settings-api",
            },
            {
                label: (
                    <Link to="/settings/payment-processors">
                        <BankOutlined /> Төлбөрийн сонголтууд
                    </Link>
                ),
                key: "settings-payment-processors",
            },
        ],
    },
];

export default items;
