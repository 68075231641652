import client from 'src/libs/client';
const path = '/admin/summary';

export const getSummary = async (date: Date) => {
    const res = await client.get(`${path}?date=${date}`)
    if (res.data?.status_code !== 0) {
        throw Error(res.data?.message)
    }
    return res;
}

export const getSummaries = async (startDate: Date, endDate: Date) => {
    const res = await client.get(`${path}/range?startDate=${startDate}&endDate=${endDate}`)
    if(res.data?.status_code !== 0) {
        throw Error(res.data?.message)
    }
    return res
}

export const getTransactionSummary = async (date: Date) => {
    const res = await client.get(`${path}/transactions?date=${date}`)
    console.log(`response: ${JSON.stringify(res)}`)
    if(res.data?.status_code !== 0) {
        throw Error(res.data?.message)
    }
    return res
}

export const getTransactionSummaryRange = async (startDate: Date, endDate: Date) => {
    const res = await client.get(`${path}/transactions/range?startDate=${startDate}&endDate=${endDate}`)
    console.log(`response: ${JSON.stringify(res)}`)
    if(res.data?.status_code !== 0) {
        throw Error(res.data?.message)
    }
    return res
}