import { useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getTransactionSummary } from 'src/api/summary';
import { DatePicker, Form, Button, Spin } from 'antd';
import Title from "../components/Title";
import { useQuery } from 'react-query';
import moment from 'moment';

const Transaction = () =>  {
    const [date, setDate] = useState(new Date())

    const onChange = (val, str) => {
        setDate(str)
    }

    const { data, isLoading, isFetching, refetch } = useQuery(
        "transaction",
        async () => {
            const res = await getTransactionSummary(date)
            console.log(`res: ${JSON.stringify(res.data.data)}`)
            return res.data.data
        }
    );
    const dateFormat = 'YYYY-MM-DD';
    return (
        <Spin spinning={isLoading || isFetching}>
            <div className="flex justify-between items-center">
                <Title title="Гүйлгээний тайлан" />
            </div>
            <div style={{"display": "flex", "alignItems": "center", "marginLeft": "5rem"}}>
            <Form
                        // form={form}
                        className="flex"
                    >
                        <Form.Item
                            label="Огноогоор хайх"
                            style={{ marginBottom: 8, marginLeft: 10 }}
                            name="Picker"
                        >
                            <DatePicker defaultValue={moment(moment(), dateFormat)} onChange={onChange} format={dateFormat} />
                        </Form.Item>
                        <Button style={{"marginLeft": ".5rem"}} onClick={() => refetch()}>
                            Хайх
                        </Button>
                    </Form>
            </div>
            <div style={{"width": "100%", "height": "70%", "marginTop": "2rem"}}>
      <ResponsiveContainer width="100%" height="50%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="amount" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
      </div>
        </Spin>
    );
}

export default Transaction